import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { ApplyContext } from "../../context/applyContext";
import Table from "../../components/Adminprocess/Table";
import axios from "axios";
import { AuthContext } from "../../context/authContext";

function Adminprocess() {
  const { setState, stage, currentUser } = useContext(AuthContext);
  const [stage2, setStage2] = useState(stage) || null;
  //const { applications } = useContext(ApplyContext);
  const [applications, setApplications] = useState([]);
  const [admins, setAdmins] = useState([]);
  const navigate = useNavigate();
  const [finalists, setFinalists] = useState();
  const [approvedPosts, setApprovedPosts] = useState();

  useEffect(() => {
    if (currentUser && currentUser?.role == "user") {
      navigate("/");
    }
  }, [currentUser]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/stage/`);
        const response = await res.json();
        setStage2(response.stage);
        const res2 = await fetch(
          `${process.env.REACT_APP_BASE_URL}/users/admins/`
        );
        const response2 = await res2.json();

        setAdmins(response2);
        const month = new Date().getMonth();
        let year = new Date().getFullYear();
        if (month < 6) {
          year = new Date().getFullYear() - 1;
        }
        const response3 = await fetch(
          `${process.env.REACT_APP_BASE_URL}/posts/year/${year}`
        );
        if (!response3.ok) {
          const message = `An error occurred: ${response3.statusText}`;
          return;
        }
        const posts = await response3.json();
        const completedPosts = posts?.filter((e) => e.completed === true);
        const approvedPosts = completedPosts?.filter((e) => e.approved == true);
        approvedPosts?.map((e) => {
          if (e.finalgrades.length > 0) {
            return (e.avgfinalgrades =
              e.finalgrades
                ?.map((e) => e.grade)
                .reduce((partialSum, a) => partialSum + a, 0) /
              e.finalgrades.length);
          } else {
            return (e.avgfinalgrades = 0);
          }
        });
        approvedPosts?.map((e) => {
          e.finalgradenumber = e.finalgrades.length;
        });
        setApprovedPosts(approvedPosts);
        const finalists = approvedPosts.filter((e) => e.finalist == true);
        setFinalists(finalists);
        if (response.stage == 3) {
          setApplications(finalists);
        } else {
          setApplications(approvedPosts);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  const updateState = (e) => {
    setApplications(e);
  };

  const handleChange = async (e) => {
    setStage2(e.target.value);
    let input = {
      stage: e.target.value,
    };
    if (e.target.value == 3) {
      setApplications(finalists);
    } else {
      setApplications(approvedPosts);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stage`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
      });
      const res = await response.json();
      setState();
    } catch (err) {}
  };
  let columns = [];
  if (stage == 1) {
    columns = [
      // { label: "Id", accessor: "id", sortable: true, sortbyOrder: "asc" },
      {
        label: "Namn",
        accessor: "name",
        sortable: true,
      },
      { label: "Titel", accessor: "title", sortable: true },
      // { label: "Kategori", accessor: "cat", sortable: true },
      { label: "Datum", accessor: "createdAt", sortable: true },
      { label: "Universitet", accessor: "university", sortable: true },
      { label: "Jäv", accessor: "bias", sortable: true },
      { label: "Tilldelad till", accessor: "scorer", sortable: true },
    ];
  } else if (stage == 2) {
    columns = [
      // { label: "Id", accessor: "id", sortable: true, sortbyOrder: "asc" },
      {
        label: "Namn",
        accessor: "name",
        sortable: true,
      },
      { label: "Titel", accessor: "title", sortable: true },
      // { label: "Kategori", accessor: "cat", sortable: true },
      { label: "Datum", accessor: "createdAt", sortable: true },
      { label: "Universitet", accessor: "university", sortable: true },
      { label: "Tilldelad till", accessor: "scorer", sortable: true },
      { label: "Finalist", accessor: "finalist", sortable: true },
    ];
  } else {
    columns = [
      // { label: "Id", accessor: "id", sortable: true, sortbyOrder: "asc" },
      {
        label: "Namn",
        accessor: "name",
        sortable: true,
      },
      { label: "Titel", accessor: "title", sortable: true },
      // { label: "Kategori", accessor: "cat", sortable: true },
      { label: "Datum", accessor: "createdAt", sortable: true },
      { label: "Universitet", accessor: "university", sortable: true },

      {
        label: "Medel Ranking",
        accessor: "avgfinalgrades",
        sortable: true,

        sortbyOrder: "asc",
      },
      {
        label: "Vinnare",
        accessor: "winner",
        sortable: true,
      },
    ];
  }
  return (
    <div className="table_container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1rem",
          flexDirection: "column",
        }}
      >
        {" "}
        <div
          className="adminprocess"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Link className="link" to={"/admin"}>
            <button style={{ marginRight: "1rem" }}>Betygsätt</button>
          </Link>
          <Link className="link" to={"/adminprocess"}>
            <button>Administrera betygsprocess</button>
          </Link>
        </div>
        <h2>Administrera betygsprocess</h2>
      </div>
      Förklaring:
      <br />
      Steg 1: Jonas godkänner ansökningar, betygsättare kan klicka i jäv samt
      Mårten kan tilldela ansökningar.
      <br />
      Steg 2: Alla kan sätta betyg 1-5 på tilldelade ansökningar.
      <br />
      Steg 3: Alla kan sätta finalbetyg (12,10, 8p, osv) på tilldelade
      ansökningar.
      <br />
      <br />
      Du som admin måste själv fördela/omfördela tilldelade ansökningar, detta
      påverkas inte av stegvalet.
      <div style={{ display: "flex", flexGrow: "1" }}>
        {stage != "1" ? (
          <button
            value={"1"}
            style={{
              flexGrow: "1",
              marginRight: "1rem",
              backgroundColor: "gray",
            }}
            onClick={handleChange}
          >
            Steg 1<br />
            Godkänna ansökningar
          </button>
        ) : (
          <button
            value={"1"}
            style={{ flexGrow: "1", marginRight: "1rem" }}
            onClick={handleChange}
          >
            Steg 1<br />
            Godkänna ansökningar
          </button>
        )}
        {stage != "2" ? (
          <button
            value={"2"}
            style={{
              flexGrow: "1",
              marginRight: "1rem",
              backgroundColor: "gray",
            }}
            onClick={handleChange}
          >
            Steg 2<br />
            Betygsätt egna
          </button>
        ) : (
          <button
            value={"2"}
            style={{ flexGrow: "1", marginRight: "1rem" }}
            onClick={handleChange}
          >
            Steg 2<br />
            Betygsätt egna
          </button>
        )}
        {stage != "3" ? (
          <button
            value={"3"}
            style={{
              flexGrow: "1",
              backgroundColor: "gray",
            }}
            onClick={handleChange}
          >
            Steg 3<br />
            Betygsätt finalister
          </button>
        ) : (
          <button value={"3"} style={{ flexGrow: "1" }} onClick={handleChange}>
            Steg 3<br />
            Betygsätt finalister
          </button>
        )}
      </div>
      {stage == 1 ? (
        <>
          {" "}
          <p style={{ marginTop: "10px", fontSize: "20px" }}>
            Totalt antal ansökningar: {applications.length}
          </p>
          <p style={{ marginTop: "10px", fontSize: "20px" }}>
            Tilldelade ansökningar:
          </p>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            {admins?.map((admin) => {
              const amount = applications?.filter(
                (application) => application.scorer === admin.id
              );
              return (
                <span style={{ fontSize: "18px" }} key={admin.id}>
                  {admin.firstname}: {amount.length}
                </span>
              );
            })}
          </div>
        </>
      ) : (
        <></>
      )}
      {applications && stage2 ? (
        <>
          <Table
            caption=""
            data={applications}
            columns={columns}
            admins={admins}
            updateState={updateState}
          />
        </>
      ) : (
        <h1>Laddar...</h1>
      )}
    </div>
  );
}

export default Adminprocess;
