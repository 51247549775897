import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";

const TableBody = ({ tableData, columns, setState, updateState, stage }) => {
  const { currentUser } = useContext(AuthContext);
  const [currentData, setCurrentData] = useState(tableData);
  const [currentStage, setCurrentStage] = useState(true);
  const scores = [0, 1, 2, 3, 4, 5];
  const finalscores = [0, 2, 4, 6, 8, 10, 12];

  useEffect(() => {
    setCurrentData(tableData);
    if (stage == 3) {
      setCurrentStage(false);
    }
  }, [tableData, stage]);
  const navigate = useNavigate();

  const postStateToDb = async (data) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/posts`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
  };

  const handleOnChange = (id) => {
    let thisData = currentData.filter((e) => e.id === id);

    if (thisData[0].approved === true) {
      thisData[0].approved = false;
      postStateToDb(thisData[0]);
    } else {
      thisData[0].approved = true;
      postStateToDb(thisData[0]);
    }
  };
  const handleBiasOnChange = (id) => {
    let thisData = currentData.filter((e) => e.id === id);
    if (thisData[0].bias.includes(currentUser.firstname)) {
      thisData[0].bias = thisData[0].bias.filter(
        (item) => item !== currentUser.firstname
      );
      postStateToDb(thisData[0]);
    } else {
      thisData[0].bias.push(currentUser.firstname);
      postStateToDb(thisData[0]);
    }
  };

  const handleOnChangeFinalGrade = (id) => (e) => {
    let thisData = currentData.filter((e) => e.id === id);
    if (
      thisData[0].finalgrades.filter((e) => e.user == currentUser.id).length > 0
    ) {
      thisData[0].finalgrades.forEach((element) => {
        if (element.user == currentUser.id) {
          element.grade = parseInt(e.target.value);
          return;
        }
      });
      postStateToDb(thisData[0]);
    } else {
      thisData[0].finalgrades.push({
        user: currentUser.id,
        grade: parseInt(e.target.value),
      });
      postStateToDb(thisData[0]);
    }
  };
  const handleFinalistOnChange = (id) => {
    let thisData = currentData.filter((e) => e.id === id);
    let theOtherData = currentData.filter((e) => e.id !== id);

    if (thisData[0].finalist === true) {
      thisData[0].finalist = false;
      postStateToDb(thisData[0]);

      theOtherData.push(thisData[0]);
      updateState(theOtherData);
    } else {
      thisData[0].finalist = true;
      postStateToDb(thisData[0]);

      theOtherData.push(thisData[0]);
      updateState(theOtherData);
    }
  };
  const handleOnChangeGrade = (id) => (e) => {
    let thisData = currentData.filter((e) => e.id === id);
    let theOtherData = currentData.filter((e) => e.id !== id);
    if (
      thisData[0].grades?.filter((e) => e.user === currentUser?.id).length > 0
    ) {
      thisData[0].grades?.forEach((element) => {
        if (element.user == currentUser.id) {
          element.grade = e.target.value;
        }
      });
    } else {
      thisData[0].grades = {
        user: currentUser.id,
        grade: e.target.value,
      };
    }

    thisData[0].grade = e.target.value;
    postStateToDb(thisData[0]);
    theOtherData.push(thisData[0]);
    setCurrentData(theOtherData);
    updateState(theOtherData);
  };
  const RenderAdmin2 = () => {
    return (
      <tbody>
        {currentData.map((data) => {
          return (
            <tr key={data.id} draggable>
              {columns.map(({ accessor }) => {
                if (accessor == "createdAt") {
                  const tData = data[accessor] ? data[accessor] : "——";
                  return (
                    <td
                      onClick={() =>
                        navigate(`/post/${data.id}`, { state: { currentData } })
                      }
                      key={accessor}
                    >
                      {tData.substring(0, 10)}
                    </td>
                  );
                } else if (accessor === "finalgrades") {
                  const tData = Object.values(data[accessor]);
                  const mytData = tData.filter((e) => e.user == currentUser.id);
                  const mygrade = mytData.map((e) => e.grade)[0];
                  return (
                    <td key={accessor}>
                      <select
                        onChange={handleOnChangeFinalGrade(data.id)}
                        style={{
                          marginLeft: "0rem",
                          cursor: "pointer",
                          width: "100%",
                          borderStyle: "none",
                          padding: 0,
                          fontSize: "15px",
                        }}
                        defaultValue={mygrade}
                      >
                        <option value="" hidden>
                          ——
                        </option>
                        {finalscores.map((score) => {
                          return (
                            <option value={score} key={score}>
                              {score}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  );
                } else {
                  const tData = data[accessor] ? data[accessor] : "——";
                  return (
                    <td
                      onClick={() =>
                        navigate(`/post/${data.id}`, { state: { currentData } })
                      }
                      key={accessor}
                    >
                      {tData}
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  const RenderAdmin = () => {
    return (
      <tbody>
        {currentData.map((data) => {
          return (
            <tr key={data.id}>
              {columns.map(({ accessor }) => {
                if (accessor === "approved") {
                  return (
                    <td style={{ cursor: "auto" }} key={accessor}>
                      <input
                        onClick={() => handleOnChange(data.id)}
                        style={{
                          marginLeft: "1.5rem",
                          cursor: "pointer",
                        }}
                        defaultChecked={data[accessor] === true}
                        type="checkbox"
                      />
                    </td>
                  );
                } else if (accessor === "finalgrades") {
                  const tData = Object.entries(data[accessor]);
                } else if (accessor === "bias") {
                  return (
                    <td
                      onClick={() => handleBiasOnChange(data.id)}
                      key={accessor}
                    >
                      <input
                        style={{
                          marginLeft: "1.5rem",
                        }}
                        defaultChecked={data[accessor].includes(
                          currentUser.firstname
                        )}
                        type="checkbox"
                      />
                    </td>
                  );
                } else if (accessor == "createdAt") {
                  const tData = data[accessor] ? data[accessor] : "——";
                  return (
                    <td
                      onClick={() =>
                        navigate(`/post/${data.id}`, { state: { currentData } })
                      }
                      key={accessor}
                    >
                      {tData.substring(0, 10)}
                    </td>
                  );
                } else if (accessor === "grade") {
                  const tData = data[accessor] ? data[accessor] : "——";
                  return (
                    <td key={accessor} style={{ padding: "0" }}>
                      <select
                        onChange={handleOnChangeGrade(data.id)}
                        style={{
                          marginLeft: "0rem",
                          cursor: "pointer",
                          borderStyle: "none",
                          fontSize: "15px",
                        }}
                        defaultValue={tData}
                      >
                        {/* TODO FIX OPTION SHOWN */}
                        <option value="" hidden>
                          ——
                        </option>
                        {scores.map((score) => {
                          return (
                            <option value={score} key={score}>
                              {score}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  );
                } else if (accessor === "finalist") {
                  return (
                    <td style={{ cursor: "auto" }} key={accessor}>
                      <input
                        style={{
                          marginLeft: "1rem",
                          cursor: "pointer",
                        }}
                        defaultChecked={data[accessor] === true}
                        type="checkbox"
                        onClick={() => handleFinalistOnChange(data.id)}
                      />
                    </td>
                  );
                } else {
                  const tData = data[accessor] ? data[accessor] : "——";
                  return (
                    <td
                      onClick={() =>
                        navigate(`/post/${data.id}`, { state: { currentData } })
                      }
                      key={accessor}
                    >
                      {tData}
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <>
      {currentStage ? (
        <RenderAdmin></RenderAdmin>
      ) : (
        <RenderAdmin2></RenderAdmin2>
      )}
    </>
  );
};

export default TableBody;
