import { React, useState, useEffect } from "react";
import Table from "../../components/Table";

function Lawyer() {
  const [applications, setApplications] = useState() || null;
  const columns = [
    // { label: "Id", accessor: "id", sortable: true, sortbyOrder: "asc" },
    {
      label: "Namn",
      accessor: "name",
      sortable: true,
      sortbyOrder: "asc",
    },
    { label: "Titel", accessor: "title", sortable: true },
    { label: "Prefekt", accessor: "prefect", sortable: true },
    { label: "Datum", accessor: "createdAt", sortable: true },
    { label: "Godkänd", accessor: "approved", sortable: true },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const month = new Date().getMonth();
        let year = new Date().getFullYear();
        if (month < 6) {
          year = new Date().getFullYear() - 1;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/posts/year/${year}`
        );
        if (!response.ok) {
          const message = `An error occurred: ${response.statusText}`;
          return message;
        }
        const posts = await response.json();
        const completedPosts = posts?.filter((e) => e.completed === true);
        setApplications(completedPosts);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  const approvedApplications = applications?.filter((e) => e.approved === true);

  return (
    <>
      <div className="table_container">
        <h1>Adminsida</h1>
        <br />
        <p>Antal ansökningar: {applications?.length} </p>
        <p>Antal godkända: {approvedApplications?.length}</p>
        {applications ? (
          <>
            <Table
              caption="Ansökningar"
              data={applications}
              columns={columns}
              setState={setApplications}
            />
          </>
        ) : (
          <h1>Laddar...</h1>
        )}
      </div>
    </>
  );
}
export default Lawyer;
